/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { Area, BHK, Price, PricePerUnit } from "../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Chip } from "@mui/material";
import PropertyCardArea from "./sub-components/PropertyCardArea";
import PropertyCardConfigAndContact from "./sub-components/PropertyCardConfigAndContact";
import PropertyCardImage from "./sub-components/PropertyCardImage";
import PropertyCardLocation from "./sub-components/PropertyCardLocation";
import PropertyCardPrice from "./sub-components/PropertyCardPrice";
import PropertyCardStatus from "./sub-components/PropertyCardStatus";
import PropertyCardTitle from "./sub-components/PropertyCardTitle";
import PropertyCardType from "./sub-components/PropertyCardType";

/* Library Function Imports */

import PrepareConfigString from "../../../lib/PrepareConfigString";

/* Styled Components */

const PropertyCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: source === "search" ? "100%" : "22.05rem",
	height: "29.65rem",
	margin: "0.1rem",
	boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: source === "search" ? "16rem" : "18rem",
		height: "100%",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source === "search" ? "17.75rem" : "18.05rem",
		height: "100%",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: source === "search" ? "20.25rem" : "20.5rem",
		height: "100%",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: source === "search" ? "21.25rem" : "21.25rem",
		height: "100%",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: source === "search" ? "22rem" : "22.05rem",
		height: "100%",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: source === "search" ? "23.5rem" : "22.05rem",
		height: "29.65rem",
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: source === "search" ? "24.5rem" : "22.05rem",
		height: "29.65rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: source === "search" ? "30.5rem" : "22.05rem",
		height: "29.65rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/* MDLG Breakpoint iPadPro*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
}));

const PropertyCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	padding: "0.75rem 1.25rem 0rem 1.25rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 16px 16px",
	cursor: "pointer",
	[theme.breakpoints.down(413)]: {
		padding: "0.75rem 1.25rem 1rem 1.25rem",
	},
}));

const TitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "space-between",
	padding: "1rem 1.25rem 0rem 1rem",
	width: "100%",
}));

const PriceAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
}));

const CustomChip = styled(Chip, {
	shouldForwardProp: (prop) => prop !== "transaction_type",
})<{ transaction_type: string }>(({ theme, transaction_type }) => ({
	height: "1.75rem",
	// fontWeight: 500,
	fontSize: "0.875rem",
	letterSpacing: "0.02857em",
	borderRadius: "8px",
	backgroundColor:
		transaction_type === "buy"
			? "#b7efc5"
			: transaction_type === "rent"
				? "#ffc6ac"
				: transaction_type === "resale"
					? "#caf0f8"
					: "#b7efc5",
	color:
		transaction_type === "buy"
			? "#1e4620"
			: transaction_type === "rent"
				? "#4c2708"
				: transaction_type === "resale"
					? "#212121"
					: "#1e4620",
}));

const CardRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "1rem",
	width: "100%",
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
}));

const PropertyCard = ({
	transaction_type,
	property_type,
	title,
	images,
	property_status,
	possession_date,
	location,
	price,
	price_per_unit,
	area,
	bhk,
	floors,
	no_of_seats,
	source,
}: {
	transaction_type: string;
	property_type: Array<string>;
	title: string;
	images: string;
	property_status: Array<string>;
	possession_date: string;
	location: string;
	price: Price;
	price_per_unit: PricePerUnit;
	area: Area;
	bhk: BHK;
	floors: BHK;
	no_of_seats: BHK;
	source?: string;
}) => {
	// const [imgPreview, setImgPreview] = React.useState<string>("");

	// // lets select the image with priority 0 if 0 not existing then select the first image

	// React.useEffect(() => {
	// 	if (images && images.length > 0) {
	// 		const image = images.find((img) => img.priority === 0);
	// 		if (image) {
	// 			setImgPreview(image.caption);
	// 		} else if (images.length === 0) {
	// 			setImgPreview("");
	// 		} else {
	// 			setImgPreview(images[0].caption);
	// 		}
	// 	} else {
	// 		setImgPreview("");
	// 	}
	// }, [images]);

	return (
		<React.Fragment>
			<PropertyCardContainer source={source ? source : ""}>
				{/* Image */}

				<PropertyCardImage images={images} />

				<TitleContainer>
					<PriceAndTransactionContainer>
						{/* Price */}

						<PropertyCardPrice price={price} />

						{/* Transaction Type Chip */}

						<CustomChip
							label={
								transaction_type && transaction_type === "buy"
									? "Sale"
									: transaction_type.charAt(0).toUpperCase() + transaction_type.slice(1)
							}
							transaction_type={transaction_type}
						/>
					</PriceAndTransactionContainer>

					{/* Title */}

					<PropertyCardTitle title={title !== "" || undefined ? title : "Unknown Title"} />
				</TitleContainer>

				<PropertyCardInfoContainer>
					<CardRow>
						{/* Property Type */}

						<PropertyCardType propertyType={property_type ? property_type : []} />

						<VerticalLine />

						{/* Location */}

						<PropertyCardLocation location={location !== "" || undefined ? location : "Unknown Location"} />
					</CardRow>

					<CardRow>
						{/* Area */}

						<PropertyCardArea
							area={area}
							areaType={
								property_type
									? property_type.includes("Land (Residential)") ||
										property_type.includes("Land (Commercial)") ||
										property_type.includes("Land (Agricultural)") ||
										property_type.includes("Land (Industrial)")
										? "land_area"
										: "super_built_up_area"
									: "super_built_up_area"
							}
						/>

						<VerticalLine />

						{/* Property Status */}

						<PropertyCardStatus
							property_status={property_status}
							possession_date={possession_date}
							transaction_type={transaction_type}
						/>
					</CardRow>

					{/* Config and CTA */}

					<PropertyCardConfigAndContact
						config={PrepareConfigString(transaction_type, property_type, bhk, price_per_unit, floors, no_of_seats)}
					/>
				</PropertyCardInfoContainer>
			</PropertyCardContainer>
		</React.Fragment>
	);
};

export default PropertyCard;
