/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import {
	setCreateNewServiceMaxPriceThunk,
	setCreateNewServiceMinPriceThunk,
	setCreateNewServiceOtherSubTypeThunk,
	setCreateNewServiceProviderNameThunk,
	setCreateNewServiceShowPriceThunk,
	setCreateNewServiceSubTypeThunk,
	setCreateNewServiceTypeThunk,
} from "../../../../../redux-magic/store";

/* Component Imports */

import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useTheme,
} from "@mui/material";

/* Function Imports */

import NumberInWords from "../../../../../lib/NumberInWords";

/* Icon Imports */

import CurrencyToNumberPipe from "../../../../../lib/CurrencyToNumberPipe";
import NumberToCurrencyPipe from "../../../../../lib/NumberToCurrencyPipe";
import PreparePriceString from "../../../../../lib/PreparePriceString";
import StateInterface from "../../../../../redux-magic/state-interface";
import { SearchFilterOptionText } from "../../../../../redux-magic/sub-interfaces/search-types/search-filter-option";

/* Styled Components */

const PaperContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-end",
	width: "100%",
	borderRadius: "8px",
	gap: "0rem",
	overflow: "auto",
	padding: "1rem 1rem 1rem 1rem",
	boxShadow: theme.palette.mode == "dark" ? "" : "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
	scrollbarWidth: "none", // Hide the scrollbar for firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
	},
	"&-ms-overflow-style:": {
		display: "none", // Hide the scrollbar for IE
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.6rem",
	width: "100%",
	padding: "0.25rem 0rem 0.25rem 0rem",
}));

const ShowPriceDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
}));

const ServiceDetails = ({
	newServiceData,
	dispatch,
	serviceTypeError,
	setServiceTypeError,
	serviceSubTypeError,
	setServiceSubTypeError,
	serviceMinPriceError,
	setServiceMinPriceError,
	serviceMaxPriceError,
	setServiceMaxPriceError,
	serviceProviderNameError,
	setServiceProviderNameError,
}: {
	newServiceData: StateInterface["new_service_data"];
	dispatch: Function;
	serviceTypeError: boolean;
	setServiceTypeError: Function;
	serviceSubTypeError: boolean;
	setServiceSubTypeError: Function;
	serviceMinPriceError: boolean;
	setServiceMinPriceError: Function;
	serviceMaxPriceError: boolean;
	setServiceMaxPriceError: Function;
	serviceProviderNameError: boolean;
	setServiceProviderNameError: Function;
}) => {
	const theme = useTheme();

	const [errorOtherSubtype, setErrorOtherSubtype] = React.useState(false);

	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);

	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	/* Functions */

	const subtype_options: Array<SearchFilterOptionText> = [];

	const construction_options: Array<SearchFilterOptionText> = [
		{
			id: "masonry_and_concrete",
			title: "Masonry and Concrete",
			value: "masonry_and_concrete",
		},
		{
			id: "earth_moving",
			title: "Earth Moving",
			value: "earth_moving",
		},
		{
			id: "demolition",
			title: "Demolition",
			value: "demolition",
		},
		{
			id: "fabrication",
			title: "Fabrication",
			value: "fabrication",
		},
		{
			id: "hvac_installation",
			title: "HVAC Installation",
			value: "hvac_installation",
		},
		{
			id: "electrical",
			title: "Electrical",
			value: "electrical",
		},
		{
			id: "plumbing",
			title: "Plumbing",
			value: "plumbing",
		},
		{
			id: "solar_panel_installation",
			title: "Solar Panel Installation",
			value: "solar_panel_installation",
		},
		{
			id: "borewell_drilling",
			title: "Borewell Drilling",
			value: "borewell_drilling",
		},
		{
			id: "rainwater_harvesting",
			title: "Rainwater Harvesting",
			value: "rainwater_harvesting",
		},
		{
			id: "fire_safety",
			title: "Fire Safety",
			value: "fire_safety",
		},
		{
			id: "structural_engineering",
			title: "Structural Engineering",
			value: "structural_engineering",
		},
		{
			id: "architecture",
			title: "Architecture",
			value: "architecture",
		},
		{
			id: "painting",
			title: "Painting",
			value: "painting",
		},
		{
			id: "carpentry_or_woodwork",
			title: "Carpentry / Woodwork",
			value: "carpentry_or_woodwork",
		},
		{
			id: "facade_and_exterior_panels",
			title: "Facade and Exterior Panels",
			value: "facade_and_exterior_panels",
		},
		{
			id: "vaastu_consultant",
			title: "Vaastu Consultant",
			value: "vaastu_consultant",
		},
		{
			id: "material_supply",
			title: "Material Supply",
			value: "material_supply",
		},
		{
			id: "landscaping",
			title: "Landscaping",
			value: "landscaping",
		},
		{
			id: "pool_consultants",
			title: "Pool Consultants",
			value: "pool_consultants",
		},
		{
			id: "sewage_treatment_plant",
			title: "Sewage Treatment Plant",
			value: "sewage_treatment_plant",
		},
		{
			id: "lift_installation",
			title: "Lift Installation",
			value: "lift_installation",
		},
		{
			id: "dg_installation",
			title: "DG Installation",
			value: "dg_installation",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const cleaning_and_maintenance_options: Array<SearchFilterOptionText> = [
		{
			id: "deep_cleaning",
			title: "Deep Cleaning",
			value: "deep_cleaning",
		},
		{
			id: "kitchen_and_bathroom_cleaning",
			title: "Kitchen and Bathroom Cleaning",
			value: "kitchen_and_bathroom_cleaning",
		},
		{
			id: "pest_control",
			title: "Pest Control",
			value: "pest_control",
		},
		{
			id: "laundry_and_dry_cleaning",
			title: "Laundry and Dry Cleaning",
			value: "laundry_and_dry_cleaning",
		},
		{
			id: "exterior_cleaning",
			title: "Exterior Cleaning",
			value: "exterior_cleaning",
		},
		{
			id: "sofa_cleaning",
			title: "Sofa Cleaning",
			value: "sofa_cleaning",
		},
		{
			id: "car_wash",
			title: "Car Wash",
			value: "car_wash",
		},
		{
			id: "sneaker_care",
			title: "Sneaker Care",
			value: "sneaker_care",
		},
		{
			id: "pool_maintenance",
			title: "Pool Maintenance",
			value: "pool_maintenance",
		},
		{
			id: "gardening",
			title: "Gardening",
			value: "gardening",
		},
		{
			id: "carpet_cleaning",
			title: "Carpet Cleaning",
			value: "carpet_cleaning",
		},
		{
			id: "water_sump_and_tank_cleaning",
			title: "Water Sump and Tank Cleaning",
			value: "water_sump_and_tank_cleaning",
		},
		{
			id: "granite_polish",
			title: "Granite Polish",
			value: "granite_polish",
		},
		{
			id: "tiles_acid_wash",
			title: "Tiles Acid Wash",
			value: "tiles_acid_wash",
		},
		{
			id: "wood_polish",
			title: "Wood Polish",
			value: "wood_polish",
		},
		{
			id: "acp_sheet_cleaning",
			title: "ACP Sheet Cleaning",
			value: "acp_sheet_cleaning",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const interiors_and_decor_options: Array<SearchFilterOptionText> = [
		{
			id: "interior_design",
			title: "Interior Design",
			value: "interior_design",
		},
		{
			id: "interior_painting",
			title: "Interior Painting",
			value: "interior_painting",
		},
		{
			id: "wallpaper_installation",
			title: "Wallpaper Installation",
			value: "wallpaper_installation",
		},
		{
			id: "flooring",
			title: "Flooring",
			value: "flooring",
		},
		{
			id: "home_automation",
			title: "Home Automation",
			value: "home_automation",
		},
		{
			id: "furniture",
			title: "Furniture",
			value: "furniture",
		},
		{
			id: "false_ceiling",
			title: "False Ceiling",
			value: "false_ceiling",
		},
		{
			id: "electrical_fixtures",
			title: "Electrical Fixtures",
			value: "electrical_fixtures",
		},
		{
			id: "lighting",
			title: "Lighting",
			value: "lighting",
		},
		{
			id: "bathroom_interiors",
			title: "Bathroom Interiors",
			value: "bathroom_interiors",
		},
		{
			id: "partitions",
			title: "Partitions",
			value: "partitions",
		},
		{
			id: "blinds_and_curtains",
			title: "Blinds and Curtains",
			value: "blinds_and_curtains",
		},
		{
			id: "carpentry_or_woodwork",
			title: "Carpentry / Woodwork",
			value: "carpentry_or_woodwork",
		},
		{
			id: "wardrobe",
			title: "Wardrobe",
			value: "wardrobe",
		},
		{
			id: "fabrication",
			title: "Fabrication",
			value: "fabrication",
		},
		{
			id: "modular_kitchen",
			title: "Modular Kitchen",
			value: "modular_kitchen",
		},
		{
			id: "photo_and_art_framing",
			title: "Photo and Art Framing",
			value: "photo_and_art_framing",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const home_utilities_options: Array<SearchFilterOptionText> = [
		{
			id: "lpg",
			title: "LPG",
			value: "lpg",
		},
		{
			id: "internet_and_networking",
			title: "Internet and Networking",
			value: "internet_and_networking",
		},
		{
			id: "water_supply",
			title: "Water Supply",
			value: "water_supply",
		},
		{
			id: "dish_and_cable",
			title: "Dish and Cable",
			value: "dish_and_cable",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const daily_home_care_and_security_options: Array<SearchFilterOptionText> = [
		{
			id: "cook",
			title: "Cook",
			value: "cook",
		},
		{
			id: "maid_or_housekeeping",
			title: "Maid / Housekeeping",
			value: "maid_or_housekeeping",
		},
		{
			id: "groceries_delivery",
			title: "Groceries Delivery",
			value: "groceries_delivery",
		},
		{
			id: "newspaper_and_magazine_delivery",
			title: "Newspaper and Magazine Delivery",
			value: "newspaper_and_magazine_delivery",
		},
		{
			id: "security_services",
			title: "Security Services",
			value: "security_services",
		},
		{
			id: "cctv",
			title: "CCTV",
			value: "cctv",
		},
		{
			id: "nanny",
			title: "Nanny",
			value: "nanny",
		},
		{
			id: "gardening_and_landscaping",
			title: "Gardening and Landscaping",
			value: "gardening_and_landscaping",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];
	const home_and_appliance_repair_options: Array<SearchFilterOptionText> = [
		{
			id: "ac_repair",
			title: "AC Repair",
			value: "ac_repair",
		},
		{
			id: "refrigerator_repair",
			title: "Refrigerator Repair",
			value: "refrigerator_repair",
		},
		{
			id: "washing_machine_repair",
			title: "Washing Machine Repair",
			value: "washing_machine_repair",
		},
		{
			id: "stove_repair",
			title: "Stove Repair",
			value: "stove_repair",
		},
		{
			id: "microwave_repair",
			title: "Microwave Repair",
			value: "microwave_repair",
		},
		{
			id: "tv_repair",
			title: "TV Repair",
			value: "tv_repair",
		},
		{
			id: "water_purifier_repair",
			title: "Water Purifier Repair",
			value: "water_purifier_repair",
		},
		{
			id: "geyser_repair",
			title: "Geyser Repair",
			value: "geyser_repair",
		},
		{
			id: "computer_repair",
			title: "Computer Repair",
			value: "computer_repair",
		},
		{
			id: "mobile_repair",
			title: "Mobile Repair",
			value: "mobile_repair",
		},
		{
			id: "watch_repair",
			title: "Watch Repair",
			value: "watch_repair",
		},
		{
			id: "chimney_repair",
			title: "Chimney Repair",
			value: "chimney_repair",
		},
		{
			id: "mixer_and_grinder_repair",
			title: "Mixer and Grinder Repair",
			value: "mixer_and_grinder_repair",
		},
		{
			id: "lift_repair",
			title: "Lift Repair",
			value: "lift_repair",
		},
		{
			id: "solar_panel_repair",
			title: "Solar Panel Repair",
			value: "solar_panel_repair",
		},
		{
			id: "dg_repair",
			title: "DG Repair",
			value: "dg_repair",
		},
		{
			id: "borewell_and_motor_repair",
			title: "Borewell and Motor Repair",
			value: "borewell_and_motor_repair",
		},
		{
			id: "electrical_repair",
			title: "Electrical Repair",
			value: "electrical_repair",
		},
		{
			id: "plumbing_repair",
			title: "Plumbing Repair",
			value: "plumbing_repair",
		},
		{
			id: "carpentry_or_woodwork_repair",
			title: "Carpentry / Woodwork Repair",
			value: "carpentry_or_woodwork_repair",
		},
		{
			id: "roof_repair",
			title: "Roof Repair",
			value: "roof_repair",
		},
		{
			id: "window_repair",
			title: "Window Repair",
			value: "window_repair",
		},
		{
			id: "electronics_repair",
			title: "Electronics Repair",
			value: "electronics_repair",
		},
		{
			id: "knife_and_scissors_sharpening",
			title: "Knife and Scissors Sharpening",
			value: "knife_and_scissors_sharpening",
		},
		{
			id: "jewellery_repair",
			title: "Jewellery Repair",
			value: "jewellery_repair",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const transport_options: Array<SearchFilterOptionText> = [
		{
			id: "packers_and_movers",
			title: "Packers and Movers",
			value: "packers_and_movers",
		},
		{
			id: "tempo_and_truck_hire",
			title: "Tempo and Truck Hire",
			value: "tempo_and_truck_hire",
		},
		{
			id: "cab_or_taxi",
			title: "Cab / Taxi",
			value: "cab_or_taxi",
		},
		{
			id: "car_or_bike_rental",
			title: "Car / Bike Rental",
			value: "car_or_bike_rental",
		},
		{
			id: "car_or_bike_repair",
			title: "Car / Bike Repair",
			value: "car_or_bike_repair",
		},
		{
			id: "driver",
			title: "Driver",
			value: "driver",
		},
		{
			id: "towing",
			title: "Towing",
			value: "towing",
		},
		{
			id: "courier_and_cargo",
			title: "Courier and Cargo",
			value: "courier_and_cargo",
		},
		{
			id: "travel_and_tourism",
			title: "Travel and Tourism",
			value: "travel_and_tourism",
		},
		{
			id: "shipping",
			title: "Shipping",
			value: "shipping",
		},
		{
			id: "train",
			title: "Train",
			value: "train",
		},
		{
			id: "air_cargo",
			title: "Air Cargo",
			value: "air_cargo",
		},
		{
			id: "crane",
			title: "Crane",
			value: "crane",
		},
		{
			id: "helicopter",
			title: "Helicopter",
			value: "helicopter",
		},
		{
			id: "private_jet",
			title: "Private Jet",
			value: "private_jet",
		},
		{
			id: "bus_rental",
			title: "Bus Rental",
			value: "bus_rental",
		},
		{
			id: "caravan_rental",
			title: "Caravan Rental",
			value: "caravan_rental",
		},
		{
			id: "tyre_maintenance",
			title: "Tyre Maintenance",
			value: "tyre_maintenance",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const legal_and_finance_options: Array<SearchFilterOptionText> = [
		{
			id: "legal_consultation",
			title: "Legal Consultation",
			value: "legal_consultation",
		},
		{
			id: "documentation",
			title: "Documentation",
			value: "documentation",
		},
		{
			id: "accounting_and_bookkeeping",
			title: "Accounting and Bookkeeping",
			value: "accounting_and_bookkeeping",
		},
		{
			id: "financial_services",
			title: "Financial Services",
			value: "financial_services",
		},
		{
			id: "insurance",
			title: "Insurance",
			value: "insurance",
		},
		{
			id: "loan_and_funding",
			title: "Loan and Funding",
			value: "loan_and_funding",
		},
		{
			id: "tax_filing_and_consultation",
			title: "Tax Filing and Consultation",
			value: "tax_filing_and_consultation",
		},
		{
			id: "investment_advisory",
			title: "Investment Advisory",
			value: "investment_advisory",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];
	const grooming_and_other_personal_care_options: Array<SearchFilterOptionText> = [
		{
			id: "salon",
			title: "Salon",
			value: "salon",
		},
		{
			id: "spa",
			title: "Spa",
			value: "spa",
		},
		{
			id: "tailor",
			title: "Tailor",
			value: "tailor",
		},
		{
			id: "optician",
			title: "Optician",
			value: "optician",
		},
		{
			id: "stylist",
			title: "Stylist",
			value: "stylist",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const liaisoning_options: Array<SearchFilterOptionText> = [
		{
			id: "liaisoning",
			title: "Liaisoning",
			value: "liaisoning",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const social_service_options: Array<SearchFilterOptionText> = [
		{
			id: "social_service",
			title: "Social Service",
			value: "social_service",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const food_options: Array<SearchFilterOptionText> = [
		{
			id: "restaurant",
			title: "Restaurant",
			value: "restaurant",
		},
		{
			id: "catering",
			title: "Catering",
			value: "catering",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const hospitality_options: Array<SearchFilterOptionText> = [
		{
			id: "hotels_and_resorts",
			title: "Hotel and Resorts",
			value: "hotels_and_resorts",
		},
		{
			id: "service_apartments",
			title: "Service Apartments",
			value: "service_apartments",
		},
		{
			id: "home_rentals_long_term",
			title: "Home Rentals (Long Term)",
			value: "home_rentals_long_term",
		},
		{
			id: "home_rentals_short_term",
			title: "Home Rentals (Short Term)",
			value: "home_rentals_short_term",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	const other_options: Array<SearchFilterOptionText> = [
		{
			id: "pet_care",
			title: "Pet Care",
			value: "pet_care",
		},
		{
			id: "photography_or_videography",
			title: "Photography / Videography",
			value: "photography_or_videography",
		},
		{
			id: "information_technology_open_parenthesis_it_close_parenthesis",
			title: "Information Technology (IT)",
			value: "information_technology_open_parenthesis_it_close_parenthesis",
		},
		{
			id: "sports_and_fitness",
			title: "Sports and Fitness",
			value: "sports_and_fitness",
		},
		{
			id: "drone_service",
			title: "Drone Service",
			value: "drone_service",
		},
		{
			id: "storage_space",
			title: "Storage Space",
			value: "storage_space",
		},
		{
			id: "dentist",
			title: "Dentist",
			value: "dentist",
		},
		{
			id: "tattoo",
			title: "Tattoo",
			value: "tattoo",
		},
		{
			id: "event_management",
			title: "Event Management",
			value: "event_management",
		},
		{
			id: "tuition",
			title: "Tuition",
			value: "tuition",
		},
		{
			id: "personal_coaching",
			title: "Personal Coaching",
			value: "personal_coaching",
		},
		{
			id: "cobbler",
			title: "Cobbler",
			value: "cobbler",
		},
		{
			id: "tree_trimming_and_maintenance",
			title: "Tree Trimming and Maintenance",
			value: "tree_trimming_and_maintenance",
		},
		{
			id: "printing_services",
			title: "Printing Services",
			value: "printing_services",
		},
		{
			id: "astrology",
			title: "Astrology",
			value: "astrology",
		},
		{
			id: "religious_services",
			title: "Religious Services",
			value: "religious_services",
		},
		{
			id: "labour_supply",
			title: "Labour Supply",
			value: "labour_supply",
		},
		{
			id: "water_proofing",
			title: "Water Proofing",
			value: "water_proofing",
		},
		{
			id: "other",
			title: "Other",
			value: "other",
		},
	];

	/* Service subtype options */

	switch (newServiceData?.service_type[0]) {
		case "building_and_construction":
			subtype_options.push(...construction_options);
			break;
		case "cleaning_and_maintenance":
			subtype_options.push(...cleaning_and_maintenance_options);
			break;
		case "interiors_and_decor":
			subtype_options.push(...interiors_and_decor_options);
			break;
		case "home_utilies":
			subtype_options.push(...home_utilities_options);
			break;
		case "daily_homecare_and_security":
			subtype_options.push(...daily_home_care_and_security_options);
			break;
		case "home_and_appliance_repair":
			subtype_options.push(...home_and_appliance_repair_options);
			break;

		case "transport":
			subtype_options.push(...transport_options);
			break;
		case "legal_and_finance":
			subtype_options.push(...legal_and_finance_options);
			break;
		case "grooming_and_personal_care":
			subtype_options.push(...grooming_and_other_personal_care_options);
			break;
		case "liaisoning":
			subtype_options.push(...liaisoning_options);
			break;
		case "social_service":
			subtype_options.push(...social_service_options);
			break;
		case "food":
			subtype_options.push(...food_options);
			break;
		case "hospitality":
			subtype_options.push(...hospitality_options);
			break;
		case "other":
			subtype_options.push(...other_options);
			break;
		default:
			subtype_options.push();
			break;
	}

	return (
		<PaperContainer id="service-details">
			<TextContainer>
				<TextField
					value={newServiceData.service_provider.name}
					sx={{
						width: "100%",
						[theme.breakpoints.down("sm")]: {
							width: "100%",
						},
					}}
					onChange={(event: any) => {
						dispatch(setCreateNewServiceProviderNameThunk(event.target.value));
						setServiceProviderNameError(false);
					}}
					label="Service provider name*"
					autoComplete="off"
					error={serviceProviderNameError}
					helperText={serviceProviderNameError ? "Please enter a service provider name" : ""}
				/>
				<FormControl
					sx={{
						width: "100%",
						margin: "0.5rem 0rem 0rem 0rem",
						[theme.breakpoints.down("sm")]: {
							margin: "0.5rem 0rem 0rem 0rem",
						},
					}}
					error={serviceTypeError}
				>
					<InputLabel id="demo-simple-select-helper-label">Service type*</InputLabel>
					<Select
						labelId="demo-simple-select-helper-label"
						id="demo-simple-select-helper"
						value={newServiceData.service_type}
						sx={{
							width: "100%",
							height: "3.5rem",
							[theme.breakpoints.down("sm")]: {
								width: "100%",
							},
						}}
						label="Service type*"
						onChange={(event: any) => {
							const selectedValue =
								typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
							dispatch(setCreateNewServiceTypeThunk(selectedValue));
							dispatch(setCreateNewServiceSubTypeThunk([]));
							dispatch(setCreateNewServiceOtherSubTypeThunk(""));
							dispatch(setCreateNewServiceMinPriceThunk(0));
							dispatch(setCreateNewServiceMaxPriceThunk(0));
							setServiceTypeError(false);
						}}
					>
						<MenuItem value="building_and_construction">Building and construction</MenuItem>
						<MenuItem value="cleaning_and_maintenance">Cleaning and maintenance</MenuItem>
						<MenuItem value="interiors_and_decor">Interiors and decor</MenuItem>
						<MenuItem value="home_utilies">Home utilities</MenuItem>
						<MenuItem value="daily_homecare_and_security">Daily home care and security</MenuItem>
						<MenuItem value="home_and_appliance_repair"> Home and appliance repair</MenuItem>
						<MenuItem value="transport">Transport</MenuItem>
						<MenuItem value="legal_and_finance">Legal and finance</MenuItem>
						<MenuItem value="grooming_and_personal_care">Grooming and personal care</MenuItem>
						<MenuItem value="liaisoning">Liaisoning</MenuItem>
						<MenuItem value="social_service">Social service</MenuItem>
						<MenuItem value="food">Food</MenuItem>
						<MenuItem value="hospitality">Hospitality</MenuItem>
						<MenuItem value="other">Other</MenuItem>
					</Select>
					<FormHelperText>{serviceTypeError ? "Please select a service type" : ""}</FormHelperText>
				</FormControl>

				{/* Service Subtype */}

				{newServiceData.service_type.length > 0 ? (
					<TextContainer>
						<TextContainer
							sx={{
								width: "100%",
								justifyContent: "space-between",
								alignItems: "center",
								margin: "0.5rem 0rem 0rem 0rem",
								[theme.breakpoints.down("sm")]: {
									flexDirection: "column",
									gap: "1rem",
								},
							}}
						>
							<FormControl
								sx={{
									width: "100%",
									[theme.breakpoints.down("sm")]: {
										width: "100%",
									},
								}}
								error={serviceSubTypeError}
							>
								<InputLabel id="demo-simple-select-helper-label">Service subtype*</InputLabel>
								<Select
									labelId="demo-simple-select-helper-label"
									id="demo-simple-select-helper"
									value={newServiceData.service_subtype}
									sx={{
										width: "100%",
										height: "3.5rem",
										[theme.breakpoints.down("sm")]: {
											width: "100%",
										},
									}}
									label="Service subtype*"
									onChange={(event: any) => {
										dispatch(
											setCreateNewServiceSubTypeThunk(
												typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value,
											),
										);
										setServiceSubTypeError(false);
									}}
								>
									{subtype_options?.map((subtype: any, index: number) => {
										return (
											<MenuItem
												key={index}
												value={subtype.value}
											>
												{subtype.title}
											</MenuItem>
										);
									})}
								</Select>
								<FormHelperText>{serviceSubTypeError ? "Please select a service subtype" : ""}</FormHelperText>
							</FormControl>

							{newServiceData.service_subtype.includes("other") ? (
								<TextField
									value={newServiceData.other_service_subtype}
									sx={{
										width: "100%",
										[theme.breakpoints.down("sm")]: {
											width: "100%",
										},
									}}
									onChange={(event: any) => {
										dispatch(setCreateNewServiceOtherSubTypeThunk(event.target.value));
										if (newServiceData.service_subtype.includes("other") && event.target.value === "") {
											setErrorOtherSubtype(true);
										} else {
											setErrorOtherSubtype(false);
										}
									}}
									label="Other service subtype*"
									placeholder="Enter service subtype"
									autoComplete="off"
									error={errorOtherSubtype}
								/>
							) : null}
						</TextContainer>
					</TextContainer>
				) : null}

				{/* Show Price */}

				{newServiceData.service_type.length !== 0 && newServiceData.service_subtype.length !== 0 ? (
					<TextContainer>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										sx={{ margin: "0rem 0.25rem 0rem 0rem" }}
										checked={newServiceData.price.show_price}
										onChange={() => dispatch(setCreateNewServiceShowPriceThunk(!newServiceData.price.show_price))}
									/>
								}
								label="Show Price"
							/>
						</FormGroup>
					</TextContainer>
				) : null}

				{/* Price */}

				{newServiceData.service_type.length !== 0 &&
				newServiceData.service_subtype.length !== 0 &&
				newServiceData.price.show_price ? (
					<TextContainer>
						<ShowPriceDiv>
							{newServiceData.price.min === newServiceData.price.max
								? "₹ " + PreparePriceString(newServiceData.price.max)
								: "₹ " +
									PreparePriceString(newServiceData.price.min) +
									" - " +
									PreparePriceString(newServiceData.price.max)}
						</ShowPriceDiv>

						<TextContainer
							sx={{
								display: "flex",
								flexDirection: "row",
								width: "100%",
								justifyContent: "space-between",
								alignItems: "flex-start",
								margin: "0.5rem 0rem 0rem 0rem",
								[theme.breakpoints.down("sm")]: {
									flexDirection: "column",
									gap: "1.5rem",
								},
							}}
						>
							<TextField
								value={newServiceData.price.min === 0 ? "" : NumberToCurrencyPipe(newServiceData.price.min)}
								inputProps={{
									min: 0,
								}}
								sx={{
									width: "50%",
									[theme.breakpoints.down("sm")]: {
										width: "100%",
									},
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewServiceMinPriceThunk(CurrencyToNumberPipe(event.target.value)));
									if (
										(newServiceData.price.max !== 0 &&
											CurrencyToNumberPipe(event.target.value) > newServiceData.price.max) ||
										isNaN(CurrencyToNumberPipe(event.target.value))
									) {
										setErrorMinPrice(true);
										setErrorMaxPrice(true);
									} else {
										setErrorMinPrice(false);
										setErrorMaxPrice(false);
										setServiceMinPriceError(false);
									}
								}}
								label={newServiceData.price.show_price ? "Minimum Price*" : "Minimum Price"}
								error={errorMinPrice || serviceMinPriceError}
								helperText={
									errorMinPrice || serviceMinPriceError
										? "Value is more than max price"
										: NumberInWords(newServiceData.price.min)
								}
								autoComplete="off"
							/>

							<TextField
								value={newServiceData.price.max === 0 ? "" : NumberToCurrencyPipe(newServiceData.price.max)}
								inputProps={{
									min: 0,
								}}
								autoComplete="off"
								sx={{
									width: "50%",
									[theme.breakpoints.down("sm")]: {
										width: "100%",
									},
								}}
								onChange={(event: any) => {
									dispatch(setCreateNewServiceMaxPriceThunk(CurrencyToNumberPipe(event.target.value)));
									if (
										(newServiceData.price.min !== 0 &&
											CurrencyToNumberPipe(event.target.value) < newServiceData.price.min) ||
										isNaN(CurrencyToNumberPipe(event.target.value))
									) {
										setErrorMinPrice(true);
										setErrorMaxPrice(true);
									} else {
										setErrorMinPrice(false);
										setErrorMaxPrice(false);
										setServiceMaxPriceError(false);
									}
								}}
								label={newServiceData.price.show_price ? "Maximum Price*" : "Maximum Price"}
								error={errorMaxPrice || serviceMaxPriceError}
								helperText={
									errorMaxPrice || serviceMaxPriceError
										? "Value is less than min price"
										: NumberInWords(newServiceData.price.max)
								}
							/>
						</TextContainer>
					</TextContainer>
				) : null}
			</TextContainer>
		</PaperContainer>
	);
};

export default ServiceDetails;
