import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { BusinessProfileInterface, ServiceInterface, UserInterface } from "../../../../../models";
import { setCreateNewServiceUploadImagesThunk } from "../../../../../redux-magic/thunks";

/* Component Imports */

import {
	Alert,
	CircularProgress,
	DialogContent,
	FormControl,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Snackbar,
	Typography,
	useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import uploadFiles from "../../../../../lib/uploadFiles";
import validateFiles from "../../../../../lib/validateFiles";

/* Icon Imports */

import { Backup, DeleteOutline } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { ObjectId } from "bson";
import StateInterface from "../../../../../redux-magic/state-interface";
import { DbUserPage } from "../../../../../redux-magic/sub-interfaces/user-interface";

/* Interfaces */
interface LocalServiceInterface extends Omit<ServiceInterface, "created_by"> {
	created_by: {
		is_business_profile: boolean;
		business_profile_id: string | ObjectId | BusinessProfileInterface;
		user_id: string | ObjectId | UserInterface;
	};
}

interface FileAndMore {
	file: File;
	mime_type: string;
}

/* Styled Components */

const CustomDialog = styled("div")(({ theme }) => ({
	width: "100%",
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "1rem",
	padding: "1rem 0rem 0rem 0rem",
	background: theme.palette.background.paper,
}));

const InputContent = styled("input")(({ theme }) => ({
	display: "none",
}));

const UploadContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	border: "2px dashed #EE4B2B",
	borderRadius: "8px",
	cursor: "pointer",
	borderColor: theme.palette.primary.main,
	height: "10rem",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "15rem",
	height: "10.5rem",
	borderRadius: "16px",
	borderColor: theme.palette.primary.main,
}));

const SecondaryUploadContent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "15rem",
	height: "10.238rem",
	border: "2px dashed #EE4B2B",
	borderRadius: "16px",
	cursor: "pointer",
	borderColor: theme.palette.primary.main,
}));

const ImageContainer = styled("div")(({ theme }) => ({
	position: "relative",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "15rem",
}));

const PreviewImage = styled("img")(({ theme }) => ({
	width: "15rem",
	height: "10.238rem",
	borderRadius: "16px ",
	border: "3px solid rgba(0, 0, 0, 0.23)",
	borderColor: theme.palette.primary.main,
}));

const Snack = styled(Snackbar)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		bottom: "5rem",
		borderRadius: "0.5rem",
	},
}));

const FormControlContainer = styled(FormControl)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	padding: "var(--Spacing-spacing-sm, 0.5rem)",
	gap: "var(--Spacing-spacing-sm, 0.5rem)",
	alignSelf: "stretch",
	width: "100%",
	height: "3rem",
	position: "absolute",
	bottom: "0.3rem",
}));

const ProgressContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	position: "absolute",
	width: "100%",
	height: "100%",
	zIndex: 0,
}));

const ImagePreviewContainer = styled(RadioGroup)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	maxHeight: "30rem",
	gap: "1rem",
	flexWrap: "wrap",
}));

const UploadImages = ({
	newServiceData,
	session,
	dispatch,
	businessProfiles,
	isBusinessProfile,
	isNewBusinessProfile,
}: {
	newServiceData: StateInterface["new_service_data"];
	session: any;
	dispatch: Function;
	businessProfiles: DbUserPage;
	isBusinessProfile: boolean;
	isNewBusinessProfile: boolean;
}) => {
	const theme = useTheme();

	const input = React.useRef<HTMLInputElement>(null);

	const [selectedFiles, setSelectedFiles] = React.useState<Array<FileAndMore>>([]);

	const [stage, setStage] = React.useState<Array<string>>(
		newServiceData?.images.map((image) => image.stage) || ["NOT_STARTED"],
	);

	const [fileId, setFileId] = React.useState<Array<ObjectId | string | null>>(
		newServiceData?.images.map((image) => image.file_id) || [null],
	);

	const [mimeType, setMimeType] = React.useState<Array<string>>(
		newServiceData?.images.map((image) => image.mime_type) || [],
	);

	const [selectedFilePreview, setSelectedFilePreview] = React.useState<Array<string>>(
		newServiceData?.images.map((image) => image.caption) || [],
	);

	const [category, setCategory] = React.useState<Array<string>>(
		newServiceData?.images.map((image) => image.category) || [],
	);

	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const [fileErrorSnackbarText, setFileErrorSnackbarText] = React.useState<string>("File size or type not supported");

	const [load, setLoad] = React.useState<boolean>(false);

	const [isFileDialogOpen, setFileDialogOpen] = React.useState(false);

	const [priority, setPriority] = React.useState<number>(0);

	const router = React.useRef(useRouter()).current;

	interface UploadProgress {
		fileId: ObjectId | null;
		percentage: number;
		stage: string;
	}

	const handleUpload = async (validFiles: Array<FileAndMore>, filePreview: Array<string>) => {
		setLoad(true);
		await uploadFiles(
			session ? (session.authorized ? (session.session_id ? session.session_id : "") : "") : "",
			validFiles.map((file: FileAndMore) => ({
				file: file.file,
				mime_type: file.mime_type,
				original_file_id: new ObjectId(),
				original_file_server_path: "",
				original: true,
				is_business_profile: isBusinessProfile || isNewBusinessProfile ? true : false,
				business_profile_id: businessProfiles ? new ObjectId(businessProfiles.page_id) : new ObjectId(),
				entity: "service_regular",
				entity_id: new ObjectId(),
				use_case: "service_image",
				uiPath: "/",
				isPublic: false,
				text: businessProfiles ? businessProfiles.page_title : "",
			})),
			(uploadProgress: Array<UploadProgress>) => {
				const data = uploadProgress.map((progress) => progress.fileId);
				const stageInfo = uploadProgress.map((progress) => progress.stage);
				fileId[0] != null ? setFileId([...fileId, ...data]) : setFileId(data);
				stage[0] != "NOT_STARTED" ? setStage([...stage, ...stageInfo]) : setStage(stageInfo);
				setMimeType([...mimeType, ...validFiles.map((file: FileAndMore) => file.mime_type)]);
				setCategory([...category, ...validFiles.map((file: FileAndMore) => "all_images")]);
				setSelectedFilePreview([...filePreview.map((file: string) => file)]);
				const newImageArray = [
					...(newServiceData?.images.map((image) => ({
						file_id: image.file_id,
						mime_type: image.mime_type,
						category: image.category,
						priority: image.priority,
						caption: image.caption,
						stage: image.stage,
					})) || []),
					...data.map((file_id: ObjectId, index: number) => ({
						file_id: file_id,
						mime_type: validFiles[index]?.mime_type || "", // Ensure mime_type is a string
						category: "all_images",
						priority: 1,
						caption: filePreview[index],
						stage: stageInfo[index],
					})),
				].filter(
					(image, index, self) => index === self.findIndex((t) => t.file_id?.toString() === image.file_id?.toString()),
				);
				dispatch(
					setCreateNewServiceUploadImagesThunk(
						newImageArray.map((image, index) => ({
							file_id: image.file_id,
							mime_type: image.mime_type || "",
							category: image.category,
							priority: 1,
							caption: filePreview[index],
							stage: image.stage,
						})),
					),
				);
			},
		);
		setLoad(false);
	};

	// lets create a function to handle the priority of the images

	const handleUpdatePriority = (indexValue: number) => {
		setPriority(indexValue);
		dispatch(
			setCreateNewServiceUploadImagesThunk(
				newServiceData?.images.map((image, index) => ({
					file_id: image.file_id,
					mime_type: image.mime_type,
					category: image.category,
					priority: indexValue === index ? 0 : 1,
					caption: image.caption,
					stage: image.stage,
				})),
			),
		);
	};

	const handleRemoveImage = async (index: number) => {
		const newSelectedFilePreview = [...selectedFilePreview];
		const newSelectedFiles = [...selectedFiles];
		const newFileId = [...fileId];
		const newStage = [...stage];
		const newMimeType = [...mimeType];
		const newCategory = [...category];

		newSelectedFilePreview.splice(index, 1);
		newSelectedFiles.splice(index, 1);
		newFileId.splice(index, 1);
		newStage.splice(index, 1);
		newMimeType.splice(index, 1);
		newCategory.splice(index, 1);

		// Update the state with the new arrays
		setSelectedFilePreview(newSelectedFilePreview);
		setSelectedFiles(newSelectedFiles);
		setFileId(newFileId);
		setStage(newStage);
		setMimeType(newMimeType);
		setCategory(newCategory);
		dispatch(
			setCreateNewServiceUploadImagesThunk(
				newFileId.map((file_id: ObjectId, index: number) => ({
					file_id: file_id,
					mime_type: newMimeType[index],
					category: newCategory[index],
					priority: priority === index ? 0 : 1,
					caption: newSelectedFilePreview[index],
					stage: newStage[index],
				})),
			),
		);
	};

	React.useEffect(() => {
		if (isFileDialogOpen && input.current) {
			input.current.value = "";
			input.current.click();
			// Set isFileDialogOpen to false after opening the dialog
			setFileDialogOpen(false);
		}
	}, [isFileDialogOpen]);

	return (
		<React.Fragment>
			<Snack
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity="error"
					sx={{ width: "100%" }}
				>
					{fileErrorSnackbarText}
				</Alert>
			</Snack>
			{/* Upload Image Modal */}

			<CustomDialog>
				<CustomDialogContent>
					<InputContent
						ref={input}
						type="file"
						accept="image/jpeg, image/png"
						multiple={true}
						onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
							const files = event.target.files;
							const customMimeTypes = ["image/jpeg", "image/png"];
							const customValidExtensions = ["jpg", "jpeg", "png"];
							const uploadType: string = "property_or_services";
							if (files && files.length > 0) {
								const {
									validFiles,
									invalidFiles,
								}: {
									validFiles: Array<FileAndMore>;
									invalidFiles: Array<File>;
								} = await validateFiles(Array.from(files), customMimeTypes, customValidExtensions, uploadType);
								if (validFiles.length > 0) {
									setSelectedFiles([...selectedFiles, ...validFiles]);
									const filePreview = [
										...selectedFilePreview,
										...validFiles.map((file: FileAndMore) => URL.createObjectURL(file.file)),
									];
									handleUpload(validFiles, filePreview);
								}
								if (invalidFiles.length > 0) {
									const fileNames: Array<string> = invalidFiles.map((file: File) => file.name);
									setFileErrorSnackbarText(
										(fileNames.join(", ").length > 15
											? fileNames.join(", ").slice(0, 15) + "..."
											: fileNames.join(", ")) +
											(invalidFiles.length === 1 ? " is an" : " are") +
											" unsupported " +
											(invalidFiles.length === 1 ? "file" : "files") +
											" and " +
											(invalidFiles.length === 1 ? "has" : "have") +
											" been deselected automatically.",
									);
									setOpenSnackbar(true);
								} else {
									setOpenSnackbar(false);
								}
							} else {
								setOpenSnackbar(true);
							}
						}}
					/>
					{newServiceData?.images.length === 0 ? (
						<UploadContent
							onClick={(event: React.MouseEvent<HTMLElement>) => {
								if (input.current) {
									input.current.value = "";
									input.current.click();
								}
							}}
						>
							<Backup sx={{ color: theme.palette.primary.main }} />
							{/* <Typography>Add More</Typography> */}
							<Typography sx={{ color: "#9E9E9E" }}>JPEG or PNG</Typography>
							<Typography sx={{ color: "#9E9E9E" }}>Image max size 10MB</Typography>
						</UploadContent>
					) : null}
					<ImagePreviewContainer>
						{/* <RadioGroup> */}
						{selectedFilePreview.map((file: string, index: number) => (
							<ContentContainer key={index}>
								{file !== "" ? (
									<ImageContainer>
										<PreviewImage
											alt="Service Images"
											src={file}
											referrerPolicy="no-referrer"
										/>
										<FormControlLabel
											value={index}
											control={
												<Radio
													sx={{
														padding: "0rem",
													}}
													size="small"
													onClick={() => handleUpdatePriority(index)}
												/>
											}
											label={
												<Typography
													sx={{
														color: "var(--Primary-New-Contrast, #FFF)",
														fontSize: "0.625rem",
														fontWeight: 400,
														lineHeight: "166%",
													}}
												>
													Cover Photo
												</Typography>
											}
											labelPlacement="start"
											sx={{
												display: "flex",
												alignItems: "center",
												position: "absolute",
												gap: "0.25rem",
												top: "0.5rem",
												right: "0.5rem",
												height: "1.5rem",
												borderRadius: "0.25rem",
												backgroundColor: "var(--Other-New-Backdrop-Overlay, rgba(0, 0, 0, 0.50))",
												padding: "0.25rem",
												margin: "0rem",
											}}
										/>
										<ProgressContainer>
											{stage[index] && stage[index] !== "FETCHED_FILE_INFORMATION" ? (
												<CircularProgress />
											) : stage[index] === undefined || stage[index] === "FETCHED_FILE_INFORMATION" ? (
												<CheckIcon
													sx={{
														color: "#4CC417",
														fontSize: "2rem",
														backgroundColor: "rgba(0, 0, 0, 0.5)",
														borderRadius: "50%",
													}}
												/>
											) : null}
										</ProgressContainer>
										<FormControlContainer
											size="small"
											sx={{
												position: "absolute",
												bottom: "0.25rem",
											}}
										>
											{stage[index] === "FETCHED_FILE_INFORMATION" ? (
												<IconButton
													size="small"
													onClick={() => handleRemoveImage(index)}
													sx={{
														padding: "0.5rem",
														position: "absolute",
														bottom: "0.3rem",
														right: "0.5rem",
														zIndex: 1,
														background: "var(--Other-New-Backdrop-Overlay, rgba(0, 0, 0, 0.50))",
													}}
												>
													<DeleteOutline
														sx={{
															color: theme.palette.primary.main,
															fontSize: "1.25rem",
														}}
													/>
												</IconButton>
											) : null}
										</FormControlContainer>
									</ImageContainer>
								) : null}
							</ContentContainer>
						))}
						{newServiceData?.images.length === 0 || load ? null : (
							<SecondaryUploadContent
								onClick={(event: React.MouseEvent<HTMLElement>) => {
									if (input.current) {
										input.current.value = "";
										input.current.click();
									}
								}}
							>
								<Backup sx={{ color: theme.palette.primary.main }} />
								<Typography>Add More</Typography>
							</SecondaryUploadContent>
						)}
					</ImagePreviewContainer>
				</CustomDialogContent>
			</CustomDialog>
		</React.Fragment>
	);
};

export default UploadImages;
